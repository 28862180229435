@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600&family=Fredericka+the+Great&display=swap');
@import url('https://use.fontawesome.com/releases/v5.13.0/css/all.css');

:root{
	--form-color: cyan
}

html {
	height: 100%;
	font-family: 'Raleway', sans-serif;
	font-weight: 400;
	color: #333;
	background-size: contain;
	background-repeat: no-repeat;
}

body {
	margin: 0 auto;
	overflow-x: hidden;	
}

h1, h2, h3, h4, h5, h6 {
	margin: 10px 0 10px 0;
}

h1 {	
	font-size: 32px;
}

h2 {	
	font-size: 31px;
}

h3 {	
	font-size: 25px;
}

h4 {
	font-size: 20px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 16px;
}

a {
	text-decoration: none;
	color: #333;
}

.terms-page a, #news-body a {
	text-decoration: underline;
	color: #5a4935;
}

.terms-page #footer a, #news-body #footer a {
	text-decoration: unset;
	color: unset;
}

.resource-link {
	text-decoration: underline;
	color: #5a4935;
}

p {	
	font-family: 'Raleway', sans-serif;
}

input {
	height: 40px;
}

input, textarea, .dropzone {
	font-family: 'Raleway', sans-serif;
	border-width: 1px;
	border-radius: 5px;	
	padding: 10px;
	font: 400 13px 'Raleway';    
	box-sizing: border-box;	
	font-size: 16px;	
  	background-color: #FFF;
	border: 1px solid #8c72528e;
}

textarea {
	width: 100%;
	height: 200px;
	resize: none;
}

.long-textarea {
	height: 900px;
}

hr {
	height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;    
    border-radius: 2px;
    border-color: #eeeeee;
    background-color: #fafafa;
    color: #8C7252;
    outline: none;
	transition: border .24s ease-in-out;
	border: 1px dashed #8C7252;
	text-align: center;	
	justify-content: center;
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	height: 130px;
}

.dropzone div {	
	cursor: pointer;
	width: 100%;
	height: 100%;
	display: flex;
	place-items: center;	
	justify-content: center;
}

.dropzone .boxed {
	border-style: dotted;
	padding: 5px;
}

.dropzone img {
	padding: 30px;
	width: 50px;
	height: 50px;
}

.teacher-dropzone img {	
	width: 50px;
	height: 50px;
}

.curriculum-dropzone img {
	width: 84px;
	height: 48px;			
}

.green-ok-color {
	color: green;
}

.red-error-color {
	color: darkred;
}

.blue-info-color {
	color: navy;
}

.orange-question-color {
	color: #d37a0e;
}

.brown-color {
	color: #5a4935;
}

::-webkit-input-placeholder { /* Edge */
	color: #4F4F4F;
}
  
::-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #4F4F4F;
}
  
::placeholder {
	color: #4F4F4F;
}

.section {
	margin: 0px 0px 0px 0px;
	padding: 30px 90px 50px 90px;	
}

.panel {
	padding: 10px 0px 10px 0px;
}

.side-panel {
	padding: 0px 10px 0px 10px;
}

.monospace {
	font-style: normal;
	font-family: monospace;
}

.lines-limit-1 {
	white-space: nowrap;
	text-overflow: ellipsis;
}

.lines-limit-2 {
	display: block;
  	text-overflow: ellipsis;
  	word-wrap: break-word;
  	overflow: hidden;
  	max-height: 2.7em;
  	line-height: 1.4em;
}

.logo-color {
	color: #8C7252;
}

.logo-font {
	font-family: 'Fredericka the Great';
}

.row-padding-10 {
	padding: 0 10px 0 10px;
}

.row-padding-20 {
	padding: 0 10px 0 10px;
}

.col-padding-20 {
	padding: 20px 0 20px 0;
}

.underlined {
	text-decoration: underline;
}

.line-through {
	text-decoration: line-through;
	opacity: 0.5;
}

.before-discount-price {
	margin-left: 10px;	
}

.opac5 {
	opacity: 0.5;
}

.opac {
	opacity: 0.7;	
}

.label-icon {
	opacity: 0.7;
}

.shadowed-text  {
	text-shadow: 1px 1px #5a4935;
}

.no-select-text {
	user-select: none;
}

.button {
	font-family: 'Raleway', sans-serif;
	top: 0;	
	position: relative;
	margin: 0 10px 0 10px;	
	cursor: pointer;
	border-style: none;
	font-size: 14px;
	/* prevent selection of text inside keys */
	user-select: none;
	/* Smoothing out hover and active states using css3 transitions */
	transition: all 0.2s ease;
}

.left-right-padded-button {
	padding-left: 10px;
	padding-right: 10px;
}

.text-button {	
	color: rgb(80, 79, 79);	
	border-radius: 5px;	
	line-height: 25px;
	height: 25px;	
	text-align: center;
}

.navigation-button {
	font-size: 16px;
}

.min-width-text-button {
	min-width: 120px;	
}

.bordered-text-button {
	width: 70px;
	height: 25px;
	line-height: 25px;
	font-size: 15px;
	border-style: solid;
	border-radius: 8px;
	border-width: 1px;	
}

.colored-bordered-text-button {
	border: 1px solid #8C7252;
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
}

.wide-text-button {
	width: 90px;
}

.superwide-text-button {
	min-width: 120px;
}

.super2wide-text-button {
	min-width: 180px;
}

.shadowed-text-button {
	color:white;	
	border-style: none;	
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);	
	background-color: #8C7252;
}

.clear-shadowed-text-button {
	background-color: white;
	border-color: #8C7252;	
	color: #4F4F4F;	
	border-style: solid;	
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);	
}

.yellow-shadowed-text-button {
	background-color: #f7e6c7;
}

.red-shadowed-text-button {
	background-color: #ddbbbb;
}

.cta-text-button {
	height: 46px;
	width: 225px;
	line-height: 46px;
	font-size: 18px;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
	margin-top: 10px;  
}

.wide-cta-text-button {
	width: 360px;
	max-width: calc(100vw - 90px);
}

.no-box-shadow {
	box-shadow: none;
}


.h1size {
	margin: 10px;
	font-weight: 500;
	font-size: 39px;
}

.h2size {
	margin: 10px;
	font-size: 32px;
}

.h3size {	
	font-size: 20px;
}

.h4size {	
	font-size: 18px;	
}

.h4size-2lines {
	max-height: 50px;
	line-height: 25px;
}

.h5size {	
	font-size: 16px;
}

.h6size {	
	font-size: 16px;
}

.h7size {	
	font-size: 14px;
	line-height: 14px;
}

.h8size {	
	font-size: 12px;
	line-height: 14px;
}
		
.h7size-2lines {
	max-height: 38px;
	line-height: 19px;
}

.size14 {
	font-size: 14px;
}

.size16 {
	font-size: 16px;	
	font-weight: 300;	
}

.size20 {
	font-size: 20px;
	font-weight: 300;
}

.size18 {
	font-size: 18px;
	font-weight: 300;
}

.size22 {
	font-size: 22px;
	font-weight: 300;
}

.size25 {
	font-size: 25px;
	font-weight: 400;	
}

.size31 {	
	font-weight: 400;
	font-size: 31px;
}

.size36-no-weight {		
	font-size: 36px;
}

.size39 {
	font-weight: 400;
	font-size: 39px;
}

.size128 {	
	font-size: 128px;
}

.weight200 {
	font-weight: 200;
}

.weight400 {
	font-weight: 400;
}

.weight500 {
	font-weight: 500;
}

.weight600 {
	font-weight: 600;
}

.line-height12 {
	line-height: 12px;
}

.boxed-div {
	border-color: #eeeeee;    
    background-color: #fafafa;
	border-radius: 5px;
	border: 1px solid #eeeeee;
	padding: 10px;
	margin: 5px 5px 5px 0px;
}

.transparent-background {
	background-color: transparent;
}

.dotted-border {
	border-style: dotted;
}

.dashed-border {
	border-style: dashed;
}

.A4-print-page {
	/*border: 2px solid #4F4F4F;*/
	background: white;
	width: 18.7cm;
	/*height: 23.7cm;*/
	display: block;
	/*margin: 0 auto;*/
	padding: 0.5cm;
	margin-bottom: 0.5cm;
	/*box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);*/
	overflow-y: visible;
  }

  @media print {
	body, .A4-print-page {
	  margin: 0;
	  box-shadow: 0;
	  page-break-after: always;
	}
	.avoid-print-break-inside {
		break-inside: avoid;
	}
}


.body {	
	background-image: url("https://s3.us-east-1.amazonaws.com/public.files.termeric.education.us-east-1/images/bg-pattern.png");
	background-size: 130vw;	
	margin: 75px 0 0 0;	
}

.no-margin-body {
	margin: 0px;
}

.logo {
	width: 160px;
	padding-right: 10px;	
}

.page {
	overflow-y: hidden;	
	height: auto;	
	margin: 0px auto;
	/*background: radial-gradient(circle, #fff 20%, #ccc);*/
	/*background: linear-gradient(180deg, #A8957D 17%, #FFF 60%, #FFF 100%);	*/
}

.page .opac-background {
	background-color: white;
}

.creator-page {
	background-color: rgba(240, 240, 240, 0.507);
}

.creator-page .opac-background {
	background-color: rgba(246, 246, 246);
}

.page-body {
	min-height: calc(100vh - 335px);
	padding: 15px calc(100vw / 10) 20px calc(100vw / 10);
}

.static-page-body {
	min-height: calc(100vh - 310px);
}

.static-page-body a {
	text-decoration: unset;	
}

.padded-body {
	padding: 0px calc(100vw / 7) 0px calc(100vw / 7);
}

.no-padding-page-body {
	padding: 0px;
}

.less-padded-body {
	padding: 0px calc(100vw / 8) 0px calc(100vw / 10);
}

.tab {
	padding: 10px;
}

.tab-header {
	font-family: 'Fredericka the Great';	
	font-size: 25px;	
}

.many-tabs-list .rwt__tab {
	padding: 1.2rem 1.0rem !important;
}

.many-tabs-header-curriculum-edit {
	font-size: 23px;
}

.many-tabs-header-lesson-edit {
	font-size: 22px;	
}

.page-tabs-with-top-margin {
	margin-top: 150px;
}

.form {	
	font-size: 20px;
	margin-top: 20px;	
}

.form input, .form textarea, .form .dropzone, .form .stylized-textarea, .form p {
	font-size: 16px;
}

.form .clear-shadowed-text-button {
	margin-top: 0px;	
	height: 38px;
	line-height: 38px;	
}

.form label {
	margin: 0px 0 5px 0;	
}

.form-label {
	font-size: 20px;
}

.form-input-description {
	font-size: 16px;
	margin-bottom: 10px;
}

.form .flex-col > label {
	width: fit-content;	
}

.form .checkbox-container {
	height: 40px;
}

.form-item {
	margin: 5px 0 10px 0;
}

.form-row-padded-item {
	padding: 0 15px 0 10px;
}

.form-container {
	margin-top: 60px;
}

.form-container-extra-top-margin {
	margin-top: 80px;
}

.form-col {
	width: 40%;
	padding: 0 20px 0 20px;
}

.nothing-to-show-container {
	padding: 10px;	
	justify-self: center;
	align-self: center;
	text-align: center;
	width: 200px;
	color: #8C7252;	
	
}

.nothing-to-show {	
	opacity: 0.3;
	width: 100%;
	padding-bottom: 10px;		
}

.nothing-to-show-text {
	font-size: 20px;
	padding-left: 35px;
	transform: skewY(-12deg);
	
}

.hidden {
	display: none !important;
}

.full-page-bg-layer {	
	display: none;
	position: fixed;
	z-index: 8;
	top: 0;
	left: 0;	
	opacity: 0;
	width: 100%;
	height: 100%;
}

.full-width {
	width: 100%;
}

.inline {
	display: inline;
}

.inline-block {
	display: inline-block;
}

.block {
	display: block;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

.flex-row {
	display: flex;
	flex-direction: row;		
}

.flex-row-mobile-portrait-col {
	display: flex;
	flex-direction: row;		
}

.flex-start {
	align-items: flex-start;
}

.flex-center {
	align-items: center;
}

.flex-start-item {
	align-self: flex-start;
	margin-left: 30px;
}

.flex-end-item {
	align-self: flex-end;	
	margin: 0 0 0 auto;
}

.center-content {	
	place-content: center;
}

.text-center {
	text-align: center;
}

.short-height-input {
	width: 160px;
	height: 28px;
	line-height: 28px;
}

.short-width-input {
	width: 120px !important;
}

.select-div {
	position: relative;
	display: inline-block;
	font-size: 20px;
	color: gray;	
	text-align: end;
}

.select-menu-header {
	padding: 10px;
	color: #222;
}

.labeled-select-div {
	min-width: 200px;
}

.select-button {
	font-size: 20px;
	color: rgb(68, 67, 67);
}

.select-div-options-container {
	display: none;
	z-index: 3;
	position: absolute;
	align-items: flex-start;
	top: 35px;
	min-width: 180px;
	min-width: 180px;
	max-height: 50vh;
	background-color: #ebe4dc;
	padding: 10px;	
	box-shadow: 0 10px 10px 0 rgba(0,0,0,0.2);
	border-radius: 5px;
}

.select-div-options-container-left {
	left: calc(100% - 44px);	
}

.select-div-options-container-right {
	right: calc(100% - 109px);	
}

.select-div-options {
	width: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
}

.wide-menu-div-options {
	min-width: 230px;
}

.short-select-div-options {
	width: 50px;
}

.text-input-list, .lesson-input-list, .stylized-textarea {
	margin: 0px;	
	border-width: 0.5px;	
}

.text-input-list-item {
	width: 100%;
	margin: 10px;
}

.complex-level-1-input-list-item {
	border: 1px solid #eeeeee;
    background-color: rgb(240, 239, 239);
}

.complex-level-1-input-list-item .text-input-list {
	border: 1px solid #eeeeee;
    background-color: rgb(223, 223, 223);	
}

.simple-text-input-list-item {
	margin: 5px 10px 5px 10px;
}

.text-input-list-item-wrapper {
	touch-action: none;
}

.text-input-list-item-element {
	font-size: 16px;
	margin: 0 10px 0 10px;
}

.draggable .text-input-list-item {
	cursor: move;
}

.no-drag, .no-drag input {
	cursor: text !important;
}

.draggable .text-input-list-item input {
	cursor: move;
}

.draggable .text-input-list input {
	cursor: text;
}

.text-input-list-item input {
	width: 100%;
}

.text-input-list-add-new-item > input {
	width: calc(100% - 93px);
	border-style: dashed;
}

.text-input-list-item i {
	font-size: 16px;
}

.list-item-action-button {	
	opacity: 0.75;
	padding: 0 0px 0 5px;	
}

.list-item-drag-button {	
	opacity: 0.2;
	padding-right: 15px;
}

.text-input-list-unsortable {
	padding-right: 45px;
}

.requirement-input-list-add-new-item {
	width: calc(100% - 103px);
}

.lesson-input-list {
	width: calc(100% - 57px);
}

.lesson-input-list > .text-input-list {
	border-style: dotted;
	border-color: gray;
}

.course-plan-section-title-input {
	width: 100% !important;	
	border-style: dotted;
	border-color: gray;	

}

.lesson-input-list .text-input-list-item {	
	margin: 0px 10px 0px 10px;
}

.course-plan-lesson-number-label {
	width: 135px;
	padding-right: 10px;
}

.select-option {
	text-align: left;
	width: 100%;
	font-size: 20px;
	padding: 2px 0 2px 0;
}

.lesson-input-list label {
	margin-top: 10px;
}

.tooth {
	position: absolute;
	width: 0; 
	height: 0;	
	
	top: -15px;	
	border-color: transparent transparent #ebe4dc transparent;
	border-style: solid;
	border-width: 8px;
}

.tooth-left {
	left: 10px;
}

.tooth-right {
	right: 20px;
}

.top75 {
	top: 75px;	
}

.checkbox {
	margin: 2px 12px 6px 0;	
	box-sizing: border-box;
  	height: 15px;
  	width: 15px;
  	border: 1px solid #8C7252;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
	color: #8C7252;
	cursor: pointer;
	font-size: 16px;
	line-height: 15px;
}

.row-div {
	display: flex;
	flex-direction: row;	
	place-items: center;
	flex-wrap: wrap;
}

.col-div {
	display: flex;
	flex-direction: column;	
	place-items: center;	
}

.dark-layer-bg {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 4;
	width: 100%;
	height: 100%;
	background: radial-gradient(circle, #fff 20%, #ccc);
	opacity: 0.8;
}

.dark-layer-bg-inner {
	display: flex;
	z-index: 5;
	flex-direction: column;		
	position: fixed;
	top: 0;
	left: 0;
}

.exit-button {
	position: absolute;
	top: 10px;
	right: 0px;
	width: 20px;
}

.curriculum-about-item {	
	padding: 30px;
}

.imported-html-iframe {
	display: none;	
}

.boxed-text {	
	padding: 1px 5px 1px 5px;	
	border-radius: 5px;
	border: 1px solid #8C7252;
	color: #8C7252;
	border-width: 1px;
	width: fit-content;
	height: fit-content;
}

.span-with-side-neighbors {
	margin: 0 5px 0 5px;
}

.curriculum-type-flag  {
	margin: 0 5px 5px 0;
	padding: 3px !important;
}

.place-self-start {
	place-self: flex-start;
}

.place-self-center {
	place-self: center;
}

.place-self-end {
	margin-left: auto;
}

.height20 {
	height: 20px !important;
}

.margin20 {
	margin-top: 20px;
	margin-bottom: 20px;
}

.padding10 {
	padding: 10px;	
}

.padding30 {
	padding: 30px;	
}

.left-margin0 {
	margin-left: 0px;
}

.left-margin5 {
	margin-left: 5px;
}

.left-margin5or0 {
	margin-left: 5px;
}

.right-margin0 {
	margin-right: 0px;
}

.top-margin10 {
	margin-top: 10px !important;
}

.top-margin15 {
	margin-top: 15px !important;
}

.top-margin30 {
	margin-top: 30px !important;
}

.bottom-margin10 {
	margin-bottom: 10px;
}

.bottom-margin15 {
	margin-bottom: 15px;
}

.bottom-margin20 {
	margin-bottom: 20px;
}

.bottom-margin25 {
	margin-bottom: 25px;
}

.bottom-margin35 {
	margin-bottom: 35px;
}

.bottom-margin40 {
	margin-bottom: 40px;
}

.top-margin20 {
	margin-top: 20px !important;
}

.top-margin25 {
	margin-top: 25px;
}

.top-margin40 {
	margin-top: 40px !important;
}

.width200 {
	width: 200px;
}

.width300 {
	width: 400px;
}

.white-bg {	
	border: 1px solid #eeeeee;
	background-color: #fff; 
}

.description-bg {
	border: 1px solid #eeeeee;
	background-color: rgb(240, 239, 239); 
}

.image-button {
	opacity: 0.7;
	margin: 0 10px 0 10px;
}

.round-border {
	box-sizing: border-box;
	border-radius: 50%;
	background-color: #FFF;
	border: 1.5px solid #8C7252;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
}

.thick-border {
	border-style: solid;
	border-width: 2px;
}

.header-text {	
	margin: 0px;
}

/* force scroll bar to show: */
.scroll-visible::-webkit-scrollbar {
    -webkit-appearance: none;
	-webkit-overflow-scrolling: auto;
}

.scroll-visible::-webkit-scrollbar-track {  
    border-radius: 8px;
}

.scroll-visible::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}

/* force x-scroll bar to show: */
.scroll-x-visible::-webkit-scrollbar:horizontal {
    height: 7px;
}

/* force y-scroll bar to show: */
.scroll-y-visible::-webkit-scrollbar:vertical {
    width: 7px;
}

/* animate loading divs with fading background area */
.loading {
	animation-name: opacity-fade-in-out;
	animation-duration: 1s;
	background-color: #e7d0b431;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;	
}

@keyframes opacity-fade-in-out {
	0%,100% {
	  opacity: 0;
	}	
	50% {
	  opacity: 1;
	}
}

/* animate slide in zoom a bit */
.slide-in-zoom {
	animation-name: slide-in-zoom;
	animation-duration: 1s;	
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1;
}

@keyframes slide-in-zoom {
	0% {
		transform: scale(0.98);	  	
	}
	50% {
		transform: scale(1.02);	  	
	}	
	100% {
		transform: scale(1);
	}
}

/* animate slide in up */
.slide-in-up {
	animation-name: slide-in-up;
	animation-duration: 2s;	
	animation-timing-function: ease;
	animation-iteration-count: 1;	
}

@keyframes slide-in-up {
	0% {
		opacity: 0;
	  	transform: translateY(20%);
	}	
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

/* animate slide in */
.slide-in-left {
	animation-name: slide-in-left;
	animation-duration: 2s;	
	animation-timing-function: ease;
	animation-iteration-count: 1;	
}

@keyframes slide-in-left {
	0% {
		opacity: 0;	
	  	transform: translateX(10%);
	}	
	100% {
		opacity: 1;		
		transform: translateX(0);
	}
}

@keyframes slide-in-right {
	0% {
	  	transform: translateX(0%);
	}	
	100% {
		transform: translateX(2%);
	}
}

/* animate fading in */
.fade-in {
	animation-name: opacity-fade-in;
	animation-duration: 100ms;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1;
}

.slow-fade-in {
	animation-name: opacity-fade-in;
	animation-duration: 2s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1;
}

@keyframes opacity-fade-in {
	0% {
	  opacity: 0;
	}	
	100% {
	  opacity: 1;	  
	}
}

/* animate fading out */
.fade-out {
	animation-name: opacity-fade-out;
	animation-duration: 100ms;	
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1;
}

@keyframes opacity-fade-out {
	0% {
	  opacity: 1;	  
	}	
	100% {
	  opacity: 0;	  
	}
}

.disabled {
	opacity: 0.2;
}

.disabled-button {
	cursor: default;
}

/*
	Page Background
*/

/*
#page-background {
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;	
}

#page-background-img-1 {
	position: absolute;	
	width: 714px;
	top: 160px;	
	left: 35%;
}

#page-background-img-2 {	
	position: absolute;	
	width: 710px;	
	top: 660px;	
	left: -360px;
}

#page-background-img-3 {	
	position: absolute;	
	width: 710px;	
	top: 660px;	
	right: -400px;
}
*/

/*
	Header
*/

.header {
	display: flex;
	position: fixed;
	justify-items: center;
	align-items: center;
	width: 100%;
	height: 57px;		
	justify-content: space-between;	
	background: linear-gradient(180deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0.7) 100%);
	z-index: 9;
}

.creator-header {
	background: linear-gradient(180deg, rgba(240, 240, 240, 1) 50%, rgba(240, 240, 240, 0.7) 100%);
}

#header {	
	padding: 2px 0px 2px 0px;	
}

#no-search-header {	
	padding: 0px 10px 0 10px;	
}

.mobile-disabled-bg {
	display: none;
}

#header-mobile {
	display: none;	
}

#header-menu-mobile {
	display: none;		
}

#header .logo, #no-search-header .logo {
	padding-left: calc(100vw / 15);
}

.search-panel {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50%;	
}

#search-bar-container {
	width: 100%;
}

.header-right-buttons {	
	padding-right: calc(100vw / 15);
	display: flex;
	place-items: center;
	justify-self: center;	
}

.no-search-header-right-buttons {
	margin-right: 0px;
}

#header-signin-related-buttons {
	display: flex;
	place-items: center;	
}

.header-cart-div {
	position: relative;	
	height: 30px;
}

.header-cart-button {	
	height: 100%;
	width: 30px;
	margin: 0 25px 0 0;	
}

.header-cart-count-div {	
	position: relative;
	z-index: 1;
	top: -45px;
	left: 20px;
	background-color: red;
	border-radius: 50%;
	padding: 5px;	
	width: 12px;
	height: 12px;
	line-height: 10px;
	text-align: center;	
}

.header-cart-count {	
	color: white;	
}

.user-button-icon {	
	width: 45px;
	margin: 0 15px;
}

.user-button-icon-user-image {
	width: 35px;
	height: 35px;
	margin: 0 19px;
	border-style: solid;
	border-width: 1px;
	border-radius: 50%;
	border-color: #8C7252;
}

#search-bar {	
	cursor: text;	
	margin: 0px 5px 0px 5px;
	width: 100%;
	max-width: 1200px;
	min-width: 20px;		
	height: 45px;
  	border-radius: 8px 0 0 8px;
}

/* for iOS which wants to force rounded corners on serach inputs */
#search-bar[type="search"] {
	-webkit-appearance: none;
	border-radius: 8px 0 0 8px;
}

#search-subject-select {
	width: 110px;
}

#search-button-container {
	display: flex;
	position: relative;
	border-style: solid;	
	border: 1px solid #8C7252;
	right: 6px;
	height: 43px;
	border-radius: 0 8px 8px 0;
	place-items: center;	
}

#search-button {	
	width: 28px;
}

/*
	Home Page 
*/

/* Top CTA */
#body-top {	
	padding: 10px 0 0 0;
}

#cta-top {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;	
}

#cta-top-message-container {	
	width: calc(100vw / 3.5);
}

#cta-top-background-img-1 {
	width: calc(100vw / 3);
	margin: 40px 0 0 60px;
}

.cta-top-background-img-larger {
	width: calc(100vw / 4.4) !important;	
}

#cta-top-text {
	margin: 25px 0 30px 0;
}

#cta-top-register-button {
	margin-left: 0;
}

/* Top Benefits */
#top-benefits {	
	display: flex;
	justify-items: center;		
	background-image: url('https://s3.us-east-1.amazonaws.com/public.files.termeric.education.us-east-1/images/bg-gradient-1.png');
	background-color: linear-gradient(180deg, #8C7252 0%, #FFF 100%);
	background-size: 120%;
	background-repeat: no-repeat;
	background-position-x: -70px;
	padding: 100px calc(100vw / 10) 20px calc(100vw / 10);
	flex-wrap: wrap;
	justify-content: space-evenly;
}

.top-benefits-div {
	width: calc(100vw / 5);
	display: flex;
	place-items: center;
}

.top-benefit-img {
	width: 70px;
	margin-bottom: 20px;
}

.top-benefit-span {
	min-height: 120px;	
}

/* Main Subjects */
#subjects {
	text-align: center;	
	min-height: 400px;	
	padding-top: 60px;
	color: #4F4F4F;	
}

#subjects-background-img-1 {
	position: absolute;
	right: -360px;
	top: 105%;
	width: 714px;
}

.toggle-button {
	display: flex;
	place-items: center;
	width: 45px;
	height: 45px;
	padding: 8px 5px 5px 5px;	
	font-size: 22px;
}

.toggle-button i {
	width: 45px;
}

.toggle-link {
	text-align: center;
	padding: 0;	
}

#subjects-panel {	
	display: flex;
	flex-direction: row;	
	place-items: center;	
	padding: 40px;
	overflow-x: scroll;	
}

.subjects-panel-side-margin {
	padding: 20px;
}

#subjects-panel-container {	
	width: 100%;	
}

.subjects-panel-overlay {
	pointer-events: none;
	position: relative;
	margin-top: -200px;	
	width: 100px;
	max-width: calc(100vw / 10);
	height: 200px;
}

/* gradient on the left/right side margins of subjects panel: */
#subjects-panel-overlay-1 {
	background: linear-gradient(90deg,rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);	
}

#subjects-panel-overlay-2 {
	background: linear-gradient(-90deg,rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
	margin: 0 0 0 auto;
	margin-top: -200px;
}

.subject-curriculums-panel-container {
	margin-top: 40px;
}

.subject-curriculums-panel {
	display: flex;
	justify-content: center;
	place-items: center;
	padding-top: 30px;	
}

.curriculum-view-span-container span {
	display: block;	
	padding: 4px 5px 5px 5px;
	text-overflow: ellipsis;
	word-wrap: break-word;
	overflow: hidden;	
	text-align: left;	
}

.search-results-view-span-container span {
	display: block;
	padding: 5px 5px 7px 5px;
}

.search-results-view-span-container span:first-child {	
	padding-top: 0;
}

#search-results-summary {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-top: 20px;
}

#search-results-section span {	
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 0 10px 0 0;
}

#search-subject-header span {
	padding: 0 10px 0 10px;
}

span .curriculum-view-price {
	margin-top: 10px;
	font-size: 12px;
}

.curriculum-view-rating {	
	background-size: contain;
	background-repeat: repeat-x;	
	width: 75px;
	height: 15px;
	margin: 0 0 0 3px;	
}

.curriculum-card-view-rating {	
	width: 100px;
	height: 20px;
}

.rating-select-view-rating {	
	width: 200px;
	height: 40px;
	margin: 0;
}

.form .curriculum-view-rating-fg {
	top: -40px;
	left: -2px;
}

.boxed {
	border-radius: 5px;
	border: 1px solid #8C7252;
}

.curriculum-view-grade {
	text-decoration: underline;
	color: #8C7252;
	padding: 0;
}

.curriculum-view-teacher  {
	opacity: 0.7;
}

.curriculum-view-rating-stars {
	position: relative;	
	top: 7px;
}

.curriculum-view-rating-bg {
	z-index: 1;
	background-image: url('https://s3.us-east-1.amazonaws.com/public.files.termeric.education.us-east-1/images/star-icon.png');
}

.curriculum-view-rating-fg {	
	position: relative;
	top: -15px;
	background-image: url('https://s3.us-east-1.amazonaws.com/public.files.termeric.education.us-east-1/images/fill-star-icon.png');	
}

.curriculum-card-view-rating-fg {	
	top: -20px;
}

.subject-curriculums {
	display: grid;
	margin: 0 auto;
	text-align: center;
	justify-items: center;
	align-items: center;
	grid-auto-flow: column;
	grid-template-columns: auto;	
	column-gap: 30px;	
	min-height: 370px;
	padding: 0 10px 0 10px;
}

.toggle-button-next {
	transform: scaleX(-1);
}

.subject-button-div {
	display: flex;
	flex-direction: column;	
	place-items: center;
	min-width: 140px;
}

.subject-button-div span, .subject-button-div a {	
	padding-top: 10px;	
  	color: #4F4F4F;  	
  	font-size: 20px;
  	line-height: 24px;
}

#subjects-panel {
	padding-top: 30px;
}

.subject-button-img-div {	
	box-sizing: border-box;	
  	border: 1.5px solid #808080;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
	display: flex;
	place-items: center;
	justify-content: center;
	
}

.subject-button-img-div-big {	
	height: 100px;	
	width: 100px;	
}

.subject-button-img-div-big-in-col {
	margin: 0px 0px 10px 0px;	
}

.subject-button-img-div-big-in-row {
	margin: 0px 20px 10px 20px;	
}

.subject-button-img-div-small {	
	height: 50px;
	width: 50px;
	margin: 10px 10px 10px 0px;
}

.subject-button-img {	
	box-sizing: border-box;	
	width: 60%;
}

#subject-button {
	text-align: center;
}

.curriculum-view-card {	
	box-sizing: border-box;
	border-style: dashed;
	border-width: 1px;	
	padding: 10px;	
  	height: 345px;
  	width: 245px;
  	border-radius: 5px;
  	background-color: #FFF;
  	border: 1px solid #8C7252;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
}

.curriculum-view-card-title-box {
	height: 80px;
}

.curriculum-listing-image {	
	flex-shrink: 0;
	/* aspect 16:9 */
	width: 420px;
	height: 236px;
	margin-bottom: 20px;
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
}

.round-corners {
	border-radius: 5px;
}

.curriculum-view-image {
	border-style: solid;
	border-width: 1px;
}

.curriculum-view-card-image {	
	flex-shrink: 0;
	width: 215px;	
	height: 120px;
	margin-bottom: 10px;
}

/* Footer */

#footer {
	display: flex;
	flex-direction: column;
	padding: 10px 10px 10px 30px;
	background-color: #E0E0E0;
	place-items: center;
}

#footer-1 {
	display: flex;
	flex-direction: row;
	place-items: center;
	width: 100%;
}

#footer-2 {
	margin-top: 10px;
	padding: 0px 20px;
}

#footer-menu {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-left: calc(100vw / 6);
}

#footer #footer-socials {	
	margin-left: auto;
	margin-right: calc(100vw / 6);	
}

.footer-button {
	height: 25px;
	text-align: left;
}

#footer-socials {
	display: flex;
	padding-right: 30px;
	flex-direction: row;
}

#footer-socials .img-frame {
	height: 45px;
	width: 45px;
	border-radius: 50%;
	display: flex;
	place-items: center;	
	justify-content: center;
	margin: 11px;
	background-color: #eeecec;
  	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
}

#footer-socials img {	
	width: 30px;
	
}

/* 
	Register/Signin Pages
*/

.logo-top-page {
	text-align: center;
}

.register-signin-page-body {
	width: 100%;
	display: grid;
	place-items: center;
	padding: 0 0 200px 0;
}

.register-signin-form-inputs input {
	display: block;
	color: #333;
	width: 360px;
	height: 45px;
	margin: 20px 0 10px 0;	
	font-size: 16px;	
	border-radius: 5px;
	background-color: #F2F2F2;
  	border: 1px solid #8C7252;
  	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);

}

.register-signin-page .checkbox {	
	margin: 7px;	
}

.register-signin-top-logo {
	padding: 80px 0 100px 0;
}

.page-top-logo {
	padding: 20px 0 20px 0;	
}

.register-signin-form-inputs {
	display: flex;
	flex-direction: column;
	margin: 20px 0 0 0;	
	place-items: center;
}

#signin-form-inputs {
	margin: 10px 0 0 0;
}

#register-form-agree {
	padding: 10px;
}

.checkbox-container {	
	color:#4F4F4F;
	display: flex;
	flex-direction: row;
	align-items: center;	
}

.register-signin-page .register-signin-button {
	width: 380px;
	margin: 15px 0 15px 0;
}

.register-signin-page .register-signin-already {	
	font-size: 12px;	
}

.register-signin-already .text-button {
	color: #000;
}

.register-signin-page .register-signin-already span {	
	margin: 20px;	
}

#signin-forgot {
	color: #000;
	margin: 5px;
}

#signin-signin-button {
	margin: 20px;
}

.register-signin-page h2, .register-signin-page h4 {
	color: #4F4F4F;
}

/* 
	Search Page
*/

#search-sort-panel {
	width: fit-content;
	background-color: #fafafa;	
	padding: 10px;
	height: fit-content;
	border-radius: 10px 10px 0 0;
}

#search-filter-panel-container {	
	background-color: #fafafa;	
	padding: 10px;
	margin-right: 20px;
	height: fit-content;
	width: 280px;
	border-radius: 0 0 10px 10px;
}

#search-filter-panel {	
	padding: 0 60px 0 0;	
}

#search-results-and-sort-and-filter-panels {
	margin-top: 10px;
	align-items: left;
}

#search-results-and-filter-panel {
	margin-top: 0px;	
	width: 100%;
	display: flex;
	flex-direction: row;	
}

#search-curriculum-listings {
	margin: -60px 0 0 0;
	display: flex;
	flex-direction: column;
	width: 100%;
}

#search-curriculum-listings .curriculum-result-view {
	display: flex;	
	flex-direction: row;
	padding: 10px;	
	justify-content: left;
}

#search-curriculum-listings .curriculum-result-info-view {	
	padding-left: 65px;	
}

#search-curriculum-listings .curriculum-result-info-and-price-view {
	width: 65%;	
	display: grid;	
	grid-template-columns: calc(100% - 100px) auto;
}

.curriculum-result-view {
	margin-bottom: 20px;
}

.curriculum-result-view-price {
	overflow: initial !important;
	text-align: end;	
}

.curriculum-result-view-title {
	margin-bottom: 5px;
	padding-top: 0;
}

.curriculum-result-view-image {	
	flex-shrink: 0;
	width: 230px;
	height: 135px;	
}

.results-page-toggle {	
	display: flex;
	flex-direction: row;	
	place-self: center;
	place-items: center;
}

.page-toggle-button {
	font-family: Arial, Helvetica, sans-serif;
	margin: 0 10px 0 10px;	
}

span.search-filter-panel-title-collapsible {
	width: 240px;
	font-size: 20px;
	font-weight: 300;
}

span.search-filter-type-collapsible {
	width: 220px;
}


.Collapsible {
	font-family: 'Raleway', sans-serif;	
	color: #333;	
}

.Collapsible__trigger {
	font-size: 20px;
	font-weight: 400;		
	display: block;  	
  	text-decoration: none;
  	position: relative;
	margin: 10px 0 10px 0;
	padding-right: 40px;
	width: fit-content;
}

.Collapsible__trigger:after {
	font-family: "Font Awesome 5 Free";
    font-weight: 600;
    text-decoration: inherit;    
	content: '\f0d7';
	font-size: 18px;
    position: absolute;
    right: 10px;
    top: 5px;
    display: block;
	transition: transform 300ms; 
	text-decoration: none;

}

.Collapsible__trigger.is-open:after {
	transform: rotateZ(180deg);	
}
  
.Collapsible__trigger.is-disabled {
    opacity: 0.5;
	background-color: grey; 
}
	
.Collapsible__contentInner {
	font-size: 16px;
	padding: 0px;	
	border-top: 0; 
	font-weight: 300;	
}

.Collapsible > .Collapsible__contentInner span {
	margin: 0px;	
}

.Collapsible > .Collapsible__contentInner span:last-child {
	margin-bottom: 0; 
}

/*
	Preview Page
*/

.money-back-label {
	color: unset;
	border-color: unset;	
}

.page-body-top-listing {
	display: flex;
	flex-direction: row;	
	padding: 50px 0px 0 0px;	
}

.page-body-listing-content {
	padding: 20px 0px 50px 0px;
}

.page-body-listing-content h4 {
	margin: 25px 0 10px 0;
}

.page-body-top-with-top-padding {
	padding-top: 70px;
}

.top-listing-rating-container h6 {
	padding: 0 6px 0 10px;
}

.top-listing-rating-stars {
	width: 60px;
	margin: 0 25px 0 5px;
}

.top-listing-other-info h6 {
	padding: 0 10px 0 10px;
}

.top-listing-ctas {
	padding-top: 20px;
}

.top-listing-image-and-ctas {
	display: flex;
	flex-direction: column;	
	margin-left: auto;
	place-items: center;
}

#top-curriculum-listing-price {	
	align-self: start;
	margin: 0px 0 10px 30px;
}

.view-body-teacher-image {
	margin: 10px 0 10px 0;
	width: 100px;
	height: 100px;
	border: 1px solid #808080;
	box-shadow: none;
}

.curriculum-lesson-description {	
	margin: 10px 0px 10px 40px;	
}

#top-curriculum-listing-teacher-link, #view-body-teacher-link {
	font-size: 16px;
}

#top-curriculum-listing-wishlist-button {
	margin-left: 0;
}

.rwt__tab[aria-selected="true"] span {
	font-weight: 600;	
}

.rwt__tab:after {
	margin-bottom: -2px;
	border-bottom: 4px solid #F3CA3E !important;
}

#view-body-listing-content-info {
	font-size: 16px;
}

#view-body-listing-content > div:first-child {
	border-bottom: 2px solid #4F4F4F;
}

.review-item-view h6 {
	font-weight: 600;
	margin: 20px 0 0 0;
}

#view-body-listing-content-course-plan > .Collapsible > .Collapsible__trigger {
	font-size: 20px;
	font-weight: 700;
}

#view-body-listing-content-course-plan span {
	font-size: 16px;
}

.listing-table {
	padding: 10px 30px 20px 30px;
}

#view-body-listing-content-course-plan {
	margin: 40px 0 20px 0;
}

span.unit-title-collapsible {
	margin-top: 30px;
}

span.first-unit-title-collapsible {
	margin-top: 0px;
}

span.week-title-collapsible {
	margin: 5px 0 5px 20px;
	text-decoration: underline;
}

span.lesson-title-collapsible {
	font-size: 17px !important;
	font-weight: 600;
	margin: 5px 0px 5px 40px;
}

span.lesson-title-collapsible a {
	font-size: 17px !important;
	font-weight: 600;
}

/*
	Lesson Page
*/

#top-lesson-listing-info span {
	margin-right: 10px;
}

.lesson-activity-title-div-duration {
	float: right;	
}

span.lesson-activity-title-collapsible {
	width: 97%;
	margin: 5px 0px 5px 0px;
}

/*
	Library Page
*/

#library-button {
	width: 120px;
}

.library-tab-select-panel-options-div-options {
	min-width: 270px;
}

/*
	Cart Page
*/

.StripeElement {
	font-family: 'Raleway', sans-serif;
	padding: 40px 20px 20px 20px;	
}

#cart-checkout-total {
	margin-top: 0px;
}

#cart-listings-and-checkout-panel {
	width: 100%;
	display: flex;
	flex-direction: row;
	min-height: calc(100vh - 445px);
}

.curriculum-listings-panel {
	width: calc(100% - 300px);
	margin: 10px 0 0 0;
	display: flex;
	flex-direction: column;		
	min-height: calc(100vh - 445px);
}

.curriculum-listings-panel-no-sides {
	width: 100%;
}

.curriculum-listings-panel-next-to-wide-cta {
	width: calc(100% - 420px);
}

.curriculum-listings-side-panel {
	position: fixed;
	right: calc(100vw / 10);
	top: 80px;
	padding: 20px 20px 20px 20px;	
	display: flex;
	flex-direction: column;	
	align-items: center;
	z-index: 2;
}

.curriculum-listings-side-panel > span:first-child {
	margin-top: 0px;
}

.form-side-panel {
	right: 100px;
}

.curriculum-listings-panel .curriculum-item-view {
	display: flex;
	flex-direction: row;
	background-color: white;	
	padding: 10px;	
	justify-content: left;
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
	margin: 5px 0 5px 0;
}

.curriculum-listings-panel .curriculum-item-link {
	display: flex;
	flex-direction: row;
}

.curriculum-listings-panel .curriculum-item-info-view {
	display: flex;
	flex-direction: column;	
	padding-left: 20px;	
}

.curriculum-item-view-image {
	flex-shrink: 0;
	width: 120px;	
	height: 73px;	
}

.curriculum-item-view-buttons {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-left: auto;
}

.curriculum-item-view-buttons .text-button {
	text-align: left;
}

.curriculum-item-view-price {
	margin-left: auto;
	text-align: end;
	margin-right: 10px;	
}

.curriculum-item-view-single-col .curriculum-item-view-price {
	margin-left: unset;
	text-align: unset;
	margin-right: unset;
}

.flex-direction-column {
	flex-direction: column !important;
}

/*
	Teacher Page
*/

.teacher-listing-image {
	flex-shrink: 0;
	border-style: none;	
	width: 200px;
	height: 200px;
}

#top-teacher-listing-webpage-button {
	width: 180px;
	margin: 20px;
}

/*
	News Page
*/
#news-body .curriculum-listings-panel .curriculum-item-view {
	padding: 20px;
}

/*
	Creator New Page
*/

.form-select-div {	
	font: 400 13px 'Raleway';	
	font-size: 16px;
}

.form-select-div .select-button-container {
	padding: 10px;
	border-width: 1px;
	border-radius: 5px;
	box-sizing: border-box;
	border: 1px solid #8C7252;
	height: 40px;
	margin: 0;
}

.form-select-div > div {
	position: relative;
	margin: -20px 0 0 -10px;
	min-width: 240px;
}

.short-select-div > div {
	min-width: 100px;
}

.form-rating-and-review-container {
	display: flex;
	flex-direction: column;
}

#view-review-form-rating-rating-stars {
	height: 60px;
}

.item-right-of-item {
	width: 50%;
	margin: 0 0 0 20px;
}

#top-lesson-listing-curriculum-link {
	font-size: 16px;
}

.lesson-actions-select-menu {
	min-width: 140px;	
	top: 60px;
    right: -7px;
}

.lesson-actions-select-menu span {
	font-size: 16px;
}

/*
	Creator Sales page
*/
.sales-table-header {
	background-color: #d37a0e !important;
	color: white;
	font-size: 22px;
}

.sales-item {
	flex-direction: row !important;
	/*justify-content: space-evenly !important;*/
	text-align: center;
}

.sales-item-date {
	width: 25%;
}

.sales-item-curriculum {
	width: 25%;
}

.sales-item-buyer {
	width: 25%;
}

.sales-item-amount {
	width: 25%;
}

/*
	Popup Dialog Box
*/

.dialog-box-inner {
	z-index: 20;
	display: flex;	
	place-items: center;
	border-radius: 10px;
	padding: 20px;
	background: radial-gradient(circle, #fff 20%, #ecdecc);		
	min-width: 300px;
	max-width: calc(100vw - 90px);
	max-height: calc(100vh - 60px);	
	top: 50%;
	left: 50%;
	text-align: center;
	transform: translate(-50%, -50%);
	box-shadow: 0 10px 10px 0 rgba(0,0,0,0.2);
}

.dialog-box-content {
	padding: 20px;
	min-width: 300px;
	max-width: calc(100vw - 90px);	
}

.dialog-box-content .form {
	text-align: left;
}

/*
.previews-iframe {
	width: calc(100vw - 120px);
	height: calc(100vh - 110px);
}
*/

/* add Hover properties when device supports it */
@media (hover) {	
	/* Some hover effects */
	.button-hover:hover {
		color: black;
		font-weight: 500;
	}

	.text-button:hover {	
		color: black;
		font-weight: 500;
	}

	.shadowed-text-button:hover {	
		background-color: #d37a0e;
		color: rgb(240, 235, 235);
	}

	.clear-shadowed-text-button:hover {	
		background-color: rgb(218, 214, 214);
	}

	.yellow-shadowed-text-button:hover {
		background-color: #d37a0e;
		color: rgb(240, 235, 235);
	}

	.red-shadowed-text-button:hover {
		background-color: rgb(197, 120, 120);
		color: rgb(240, 235, 235);
	}

	.image-button:hover {
		opacity: 1.0;
	}

	.disabled:hover {
		opacity: 0.2;
	}
	
	.text-input-list-item-wrapper > div > .list-item-action-buttons-container, .text-input-list-item-wrapper > .list-item-drag-button  {
		opacity: 0;		
	}

	.text-input-list-item-wrapper:hover > div > .list-item-action-buttons-container {
		opacity: 1;		
	}

	.text-input-list-item-wrapper:hover > .list-item-drag-button {
		opacity: 0.2;		
	}
	
}

/* FIXME: centering subject panel for screens less wide than this will cause part of the panel on the left of the screen be unreachable via scrollbar...   */
/*
	Larger Screens
*/	
@media screen and (min-width: 1400px) {
	
	#subjects-panel {
		justify-content: center;
	}
}

/*
	Smaller/Tablet Screens
*/
@media screen and (max-width: 1370px) {
	
	.many-tabs-list .rwt__tab {
		padding: 1.2rem 0.5rem !important;
	}
	
	.many-tabs-header-curriculum-edit {
		font-size: 18px;
	}

	.many-tabs-header-lesson {
		font-size: 21px;
	}

	.many-tabs-header-lesson-edit {
		font-size: 18px;
	}

	#search-curriculum-listings {
		margin: 0 0 0 0;
	}

	#search-results-and-sort-and-filter-panels {
		align-items: center;
	}
	
	#search-results-and-filter-panel {
		flex-direction: column;
		align-items: center;
	}

	#search-results-and-filter-panel > .Collapsible > .Collapsible__trigger {
		width: 260px !important;
	}
	
	#search-filter-panel-container {
		width: 260px;
	}

	#search-results-summary {
		padding-top: 0px;
		text-align: left;		
		flex-direction: column;
		align-self: flex-start;	
		align-items: flex-start;
	}
	
	.subject-button-img-div-big-in-row {
		margin: 0 20px 10px 0px;
	}
}

/*
	Mobile Screens
*/	
@media screen and (max-width: 900px), (max-height: 550px) {
	
	.section {
		padding: 30px calc(100vw / 16) 50px calc(100vw / 16);
	}

	.wide-cta-text-button {
		width: 260px;
	}

	.curriculum-listings-panel, .curriculum-listings-panel-next-to-wide-cta {
		width: 100%;
	}
	
	.top-listing-image-and-ctas {
		margin-left: unset;
	}

	.rwt__tab {
		padding: 1.2rem 1.0rem !important;
	}
	
	/*
		Home Page
	*/

	/* Header */
	
	.header {
		display: none;	
	}	

	#header-mobile {
		display: grid;		
		grid-template-columns: 60px 70% 30px 50px;			
	}
	
	.header-menu-mobile-inner {
		z-index: 20;
		background: radial-gradient(circle, #fff 20%, #ecdecc);		
		border-radius: 10px;
		height: 100vh;
		padding: 20px 20px 100px 20px;
		z-index: 11;
		min-width: 150px;
	}
 		
	.header-menu-mobile-inner .text-button {
		color: black;
		text-align: left;	
		place-content: left;
		place-items: left;	
		font-size: 18px;
		padding: 10px;
	}

	.header-search-bar-mobile-inner {
		width: calc(100vw - 40px);
		height: 100vh;
	}

	.header-cart-button {
		margin: 0;	
	}
	
	.mobile-disabled-bg {
		display: flex;
		position: fixed;		
		background: radial-gradient(circle, #fff 20%, #ecdecc);
		top: 0;
		left: 0;		
		width: 100%;
		height: 100%;
		z-index: 99;
		text-align: center;		
		place-items: center;
		justify-content: center;		
	}

	.mobile-disabled-bg p {
		padding: 30px;
	}

	.search-button {
		width: 30px;
	}

	#header-mobile-menu-button {
		color: #8c72528a;
		font-size: 24px;
		width: 50px;
	}
	
	#header-signin-related-buttons {
		flex-direction: column;
	}

	.body {
		background-size: 200vw;			
	}

	.page-body {
		padding: 15px calc(100vw / 20) 20px calc(100vw / 20);
	}
	
	
	/* Home */
	#cta-top {
		flex-direction: column;
	}

	/* Top Benefits */
	#top-benefits {
		padding: 20px 20px 0px 20px;			
	}

	.top-benefits-div {
		width: calc(100vw / 1.5);
	}
	
	.top-benefit-span {
		min-height: unset;
	}
			
	#cta-top-message-container {	
		width: calc(100vw - 100px);
	}
	
	#cta-top-background-img-1 {
		width: calc(100vw / 1.5);
		margin: 40px 0 40px 0px;
	}
	
	.cta-top-background-img-larger {
		width: calc(100vw / 3) !important;	
	}
	
	.curriculum-listings-side-panel {
		position: unset;		
		right: unset;
		text-align: center;		
	}
	
	.form-side-panel {
		justify-self: center;
		right: unset;
	}

	/* Search */

	
	#search-curriculum-listings .curriculum-result-info-and-price-view {
		width: 65%;		
	}
	

	#header-search-bar-mobile .search-panel {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	#search-bar-container {
		margin-bottom: 15px;
	}	

	#search-curriculum-listings .curriculum-result-info-view {	
		padding-left: 25px;	
	}
	
	#search-curriculum-listings .curriculum-result-view {		
		padding: 0px;			
	}
	
	/* Preview */
	.curriculum-listing-image {	
		flex-shrink: 0;
		/* aspect 16:9 */
		width: calc(420px / 1.6);
		height: calc(236px / 1.6);
	}	

	/* Cart */
	#cart-listings-and-checkout-panel {
		flex-direction: column-reverse;
	}

	
}


/* 
	Portrait Screen
*/
@media screen and (orientation: portrait) {
		
	#header-mobile {	
		grid-template-columns: 60px 50% 30px 50px;			
	}
	
	.body {
		background-size: 200vw;			
	}
	
	.page-body-top-listing {
		flex-direction: column;	
		padding: 70px 0px 0 0px;	
	}

	.top-listing-image-and-ctas {	
		margin-top: 25px;
		margin-left: unset;
	}

	.curriculum-listings-panel .curriculum-item-view {
		flex-direction: column;
	}
	
	#footer-menu {
		margin-left: calc(100vw / 40);
	}
	
	#footer #footer-socials {	
		margin-right: calc(100vw / 40);	
	}

	#footer-socials {
		padding-right: 0px;
	}

	#footer-socials .img-frame {
		margin: 6px;
	}

	.cta-top-background-img-larger {
		width: calc(100vw / 1.7) !important;	
	}
	
	.preview-curriculum-price, .money-back-label {
		align-self: center;
		margin-left: unset;
	}
	
	/* Cart */
	.curriculum-item-view-buttons {	
		margin-top: 10px;
		place-items: flex-end;
	}	
	
}

/* 
	Super low width Portrait 
*/
@media screen and (orientation: portrait) and (max-width: 450px) {

	.tooth-left {
		left: unset;
		right: 20px;
	}
	
	.flex-row-mobile-portrait-col {		
		flex-direction: column;		
	}
	
	#footer {
		padding-left: 10px;
	}

	#footer #footer-socials {
		margin-right: calc(100vw / 100);	
	}

	.select-div-options-container-left {
		left: calc(100% - 270px);	
	}
	
	/*
	.curriculum-view-card {	
		width: 200px;
	}

	.curriculum-view-card-image {
		width: 175px;
	}
	*/
	
	.curriculum-view-card .curriculum-type-flag  {
		font-size: 10px;
	}
	
	.curriculum-view-card .left-margin5or0, .flex-row-mobile-portrait-col .left-margin5or0 {
		margin-left: 0px;
	}
	
	#search-curriculum-listings .curriculum-result-view {
		flex-direction: column;
	}

	#search-curriculum-listings .curriculum-result-info-and-price-view {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	#search-curriculum-listings .curriculum-result-info-view {
		padding: 20px 0 0 0;
	}

	#search-curriculum-listings .curriculum-result-view-price {
		margin-top: -30px;
	}

	#search-subject-header {
		flex-direction: column;
	}
	
	
}

/* 
	Super low height Landscape 
*/
@media screen and (orientation: landscape) and (max-height: 450px) {

	.dialog-box-inner {
		overflow-y: scroll;
		overflow-x: hidden;
	}

	#view-review-form textarea {
		height: 60px;
	}

	#view-review-form {
		margin-top: 0px;
	}
	
	#view-review-form .form-item {
		margin: 0px;
	}
}